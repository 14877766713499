import React from 'react'
import './about.css'
import me from '../../assets/joshpicture2.jpeg'
// import { IoIosCodeWorking } from "react-icons/io";
// import { FaUsers, FaFolder } from "react-icons/fa";



const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know Me</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={me} alt="about image" />
          </div>
        </div>

        <div className='about__content'>
          {/* <div className="about__cards">
            <article className='about__card'>
              <IoIosCodeWorking className='about__icon' />
              <h5>Experience</h5>
              <small>Web Site Creator & Web Developer</small>
            </article>
            <article className='about__card'>
              <FaUsers className='about__icon' />
              <h5>Clients</h5>
              <small>Small Businesses</small>
            </article>
            <article className='about__card'>
              <FaFolder className='about__icon' />
              <h5>Projects</h5>
              <small>5 Completed Projects</small>
            </article>
          </div> */}
          <p>Hi, I'm Josh. Nice to meet you! I am passionate about building code and applications that improve the quality of companies and individual lives. I specialize in front-end developement for clients. My goal is to serve clients in multiple areas including: individuals, small-businesses, and large corporations. I am naturally curious, a problem solver, and persistent on finishing goals and deadlines set before me. I enjoy projects of all kinds that challenge me to become a greater developer.</p>
        <a href='mailto:joshrboepple@outlook.com' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>

    </section>
  )
}

export default About
