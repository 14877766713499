import React from 'react'
import './nav.css'
import { FaHome, FaUser, FaLaptopCode } from "react-icons/fa";
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href='home' onClick={() => setActiveNav('#')} className={activeNav === 'home' ? 'active' : ''}><FaHome /></a>
      <a href='about' onClick={() => setActiveNav('#about')} className={activeNav === 'about' ? 'active' : ''}><FaUser /></a>
      <a href='portfolio' onClick={() => setActiveNav('#portfolio')} className={activeNav === 'portfolio' ? 'active' : ''}><FaLaptopCode /></a>
    </nav>
  )
}

export default Nav
