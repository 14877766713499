import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/MickeyHerringTaxidermy.jpeg'
// import IMG2 from '../../assets/TeraBoepple.jpeg'
import IMG3 from '../../assets/CharlotteAdventure.jpeg'
import IMG4 from '../../assets/UsabilitySite.jpeg'
import IMG5 from '../../assets/PhotoEssay1.jpeg'
import IMG6 from '../../assets/PhotoEssay2.jpeg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Mickey Herring Taxidermy',
    demo: 'https://mickeyherringtaxidermy.org',
  },
  // {
  //   id: 2,
  //   image: IMG2,
  //   title: 'Tera Boepple Photography',
  //   demo: 'https://teraboepplephotography.com',
  // },
  {
    id: 3,
    image: IMG3,
    title: 'Charlotte Adventure Game',
    demo: 'https://joshboeppleportfolio.com'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Usability Testing Site',
    demo: 'https://joshboeppleportfolio.com'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Meal Prep Photo Essay',
    demo: 'https://photos.google.com/u/2/share/AF1QipNuoN5hCLyl41m99-ywDsk_kTRW1ph2p-FjDY7nTw9nL-RPol9ZwVeljWJtmVbMzg/memory/AF1QipP9_ZCYo0lmrr80rm4Ewx0uN5gD9MOyEH2gtTjC0sE49Gtqw-BiSUGVk7fSJztmng?key=R0dFaUdhUXpQV01lTlEwQXVqZTdjOWtkR3hzQ3ZR'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Baby Things Photo Essay',
    demo: 'https://photos.google.com/u/2/share/AF1QipPCRVYCNU_Fk8Gqmxawx41_0tc-cf1_gwb-SGlJ00wXZO4rNYQOX4y_MCMl5Gmg2A?key=emI2dVlTdk9WbGdkX0h3Qm83Wkt1MDJZVkdSdm5B'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({ id, image, title, demo }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                  <a href={demo} className='btn btn-primary' target='noreferrer'>Live Site</a>
                </div>
              </article>
            )
          }
          )
        }
      </div>
    </section>
  )
}

export default Portfolio
