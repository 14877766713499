import React from 'react'
import './footer.css'
import { FaGithubSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";




const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>JB</a>

      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
      </ul>

      <div className="footer__socials">
        <a href='hhttps://github.com/jboe26'><FaGithubSquare /></a>
        <a href='https://www.linkedin.com/in/joshrboepple/' rel="noopener"><FaLinkedin /></a>
        
      </div>
    </footer>
  )
}

export default Footer
